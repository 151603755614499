<template>
    <section class="cobro-cartera-pagar container-fluid overflow-auto custom-scroll my-2">
        <!-- titulo -->
        <div class="row my-3 titulo-divisor">
            <div class="col-auto my-auto">
                <p class="f-600 f-14 ucfirst ">Cuentas por pagar</p>
            </div>
            <div class="col my-auto">
                <hr class="my-0 bg-white" />
            </div>
        </div>
        <!-- grafica + indicadores -->
        <div class="row">
            <div class="col-8">
                <echart :options="graficaPorPagar" />
            </div>
            <div class="col-4">
                <div class="totales ml-auto mb-3">
                    <div class="titulo bg-general text-white f-600 d-middle-center py-2 br-t-5">
                        <i class="icon-cash-multiple mr-2 f-18" />
                        <p class="my-auto f-14">Total a pagar</p>
                    </div>
                    <div class="bg-f5 text-center py-2 br-b-5 f-600 f-14">
                        <p>{{ formatoMoneda(graficaPagar.total_pagar) }}</p>
                    </div>
                </div>
                <div class="cantidades d-flex justify-content-end ml-auto f-12">
                    <div class="text-vacio"></div>
                    <div class="text-vencidas text-center f-500">Vencidas</div>
                    <div class="text-pendientes text-center f-500">Pendientes</div>
                </div>
                <div class="cantidades">
                     <div class="d-flex justify-content-end">
                        <div class="icon bg-white d-middle-center">
                            <i class="icon-clipboard-text d-none" />
                        </div>
                        <div class="vencidas f-12 d-middle-center" />
                        <div class="pendientes f-12 d-middle-center">
                            0-30
                        </div>
                        <div class="pendientes f-12 d-middle-center">
                            30-60
                        </div>
                        <div class="pendientes f-12 d-middle-center">
                            60-90
                        </div>
                        <div class="pendientes f-12 d-middle-center">
                            90+
                        </div>
                    </div>
                </div>
                <div class="cantidades ml-auto">
                    <div class="d-flex justify-content-end">
                        <div class="icon d-middle-center br-tl-5 border-right">
                            <i class="icon-clipboard-text" />
                        </div>
                        <div class="vencidas f-12 bg-f5 d-middle-center border-right border-left">
                            {{graficaPagar.tabla && graficaPagar.tabla.facturas.vencidas }}
                        </div>
                        <div class="pendientes f-12 bg-f5 d-middle-center border-right py-2">
                             {{graficaPagar.tabla && graficaPagar.tabla.facturas.primer_mes }}
                        </div>
                        <div class="pendientes f-12 bg-f5 d-middle-center border-right py-2">
                             {{graficaPagar.tabla && graficaPagar.tabla.facturas.segundo_mes }}
                        </div>
                        <div class="pendientes f-12 bg-f5 d-middle-center border-right py-2">
                             {{graficaPagar.tabla && graficaPagar.tabla.facturas.tercer_mes }}
                        </div>
                        <div class="pendientes f-12 bg-f5 d-middle-center py-2 br-tr-5">
                             {{graficaPagar.tabla && graficaPagar.tabla.facturas.superior_tres_meses }}
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div class="icon d-middle-center br-bl-5 border-right">
                            <i class="icon-hand-extended" />
                        </div>
                        <div class="vencidas f-12 bg-f5 d-middle-center border-right border-left">
                            {{graficaPagar.tabla && graficaPagar.tabla.proveedores.vencidas }}
                        </div>
                        <div class="pendientes f-12 bg-f5 d-middle-center border-right py-2">
                            {{graficaPagar.tabla && graficaPagar.tabla.proveedores.primer_mes }}
                        </div>
                        <div class="pendientes f-12 bg-f5 d-middle-center border-right py-2">
                            {{graficaPagar.tabla && graficaPagar.tabla.proveedores.segundo_mes }}
                        </div>
                        <div class="pendientes f-12 bg-f5 d-middle-center border-right py-2">
                             {{graficaPagar.tabla && graficaPagar.tabla.proveedores.tercer_mes }}
                        </div>
                        <div class="pendientes f-12 bg-f5 d-middle-center py-2 br-br-5">
                            {{graficaPagar.tabla && graficaPagar.tabla.proveedores.superior_tres_meses }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- grafica + indicadores -->
        <titulo-divisor titulo="Cuentas por pagar">
            <div class="row">
                 <div class="col auto">
                    <input-search v-model="query" :search="beforeSearch" auto-search placeholder="Buscar" size="small" />
                </div>
                 <filter-indicator :count="filterCount" @openModalFilter="abrirModalFiltros" @clearFilter="clearFilters"/>
            </div>
        </titulo-divisor>
       <tabla-general 
            :usar-servidor="false" 
            :usar-paginacion="false"
            :mostrarBuscador="false" 
            altura-tabla="calc(100vh - 390px)" 
            :data="pagar.data"
        >
            <el-table-column label="Orden" prop="orden" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600 cr-pointer" @click="verCuenta(scope.row.id)">
                        {{ scope.row.id }}
                    </p>
                </template>
            </el-table-column>
             <el-table-column label="Proveedor" prop="proveedor">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.proveedor }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="tipo" align="center" width="95">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.tipo }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Cliente" prop="cliente" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.cliente }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha de orden" prop="fechaOrden" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.fecha_orden }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Vencimiento" prop="vencimiento" align="center" width="120">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.fecha_vencimiento }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Días" prop="dias" align="center" width="120">
                <template slot-scope="scope">
                    <p class="f-12 " :class="scope.row.dias > 0 ? 'text-muted2' : 'text-danger'">
                        {{ scope.row.dias }} Días
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor" prop="valor" align="center" width="120">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneda(scope.row.valor) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Pago" prop="pago">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 d-middle">
                        {{ scope.row.pago ? scope.row.pago : '' }}
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
        <div class="row justify-content-center">
            <div class="col-auto">
                <Pagination :pagination="pagination" layout="prev, pager, next" @paginate="Action_get_pagar()" />
            </div>
        </div>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 my-3">
                    <p class="input-label-top">Tipo</p>
                    <el-select v-model="filterPago.id_tipo" placeholder="Seleccionar tipo" size="small" class="w-100">
                        <el-option v-for="item in filtroPagar.tipos" :key="item.id" :label="item.tipo" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Proveedores</p>
                    <el-select v-model="filterPago.id_proveedor" multiple placeholder="Seleccionar proveedores" size="small" class="w-100">
                        <el-option v-for="item in filtroPagar.proveedores" :key="item.id" :label="item.proveedor" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Fecha de orden</p>
                    <el-date-picker v-model="filterPago.fecha_orden" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Fecha de vencimiento</p>
                    <el-date-picker v-model="filterPago.fecha_vencimiento" type="date" placeholder="Seleccionar un día" size="small" class="w-100" />
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Estado</p>
                    <el-select v-model="filterPago.id_estado" multiple placeholder="Seleccionar un estado" size="small" class="w-100">
                        <el-option v-for="item in filtroPagar.estado" :key="item.id" :label="item.estado" :value="item.id" />
                    </el-select>
                </div>
                 <div class="col-12 my-3">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="range_valor" ref="rangeValorCotizado" range :min="filtroPagar.valor_min" :max="filtroPagar.valor_max" :format-tooltip="formatTooltipRange" />
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Días de vencimiento</p>
                    <el-slider v-model="range_dias" range :min="filtroPagar.dias_vencimiento_min" :max="filtroPagar.dias_vencimiento_max"/>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="handleFilter"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarDocumentos"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return{
            graficaPorPagar:{
                tooltip: {
                    trigger: 'item',
                    axisPointer: { 
                        type: 'none' 
                    },
                    textStyle: {
                        fontSize: 14
                    },
                    formatter: ({ name, seriesName, value }) => {
                        return `${name} <br/> ${seriesName} ${this.formatoMoneda(value)}`
                    }
                },
                xAxis: {
                    type: 'category',
                    data: ['Vencidas','0 a 30 Días', '30 a 60 Días', '60 a 90 Días', '90 a + Días'],
                    axisTick: {
                        alignWithLabel: true
                    },
                
                
                    axisLabel:{
                            //fontWeight:10,
                            //interval:2,
                            fontsize:2,
                            align:'center',
                            color:'rgba(255,255,255,0.3)'
                        }
                },
                yAxis: [{
                    type: 'value',
                
                        axisLine:{
                            show:true
                        },
                        axisLabel:{
                        
                            fontWeight:10,
                            fontsize:5,
                        color:'#BABCBF'
                        }
                        
                }],
                series: [{
                    name: 'por pagar',
                    type: 'bar',
                    stack: 'cobroCartera',
                    barWidth: 50,
                
                    itemStyle: {
                        normal: {
                            barBorderRadius: [5, 5, 0, 0],
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#0B56A7'
                                },
                                {
                                    offset: 1,
                                    color: "#ffffff"
                                }
                            ])
                        }
                    },
                    data: []
                },{
                    name: 'Por cobrar',
                    type: 'bar',
                    stack: 'cobroCartera',
                    barWidth: 50,
                    itemStyle: {
                        normal: {
                            barBorderRadius: [0, 0, 5, 5],
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#F9F9F9'
                                },
                                {
                                    offset: 1,
                                    color: "#DDDDDD"
                                }
                            ])
                        }
                    },
                    data: []
                }]
            },
            buscarListado:'',
            pickerFecha: [],
        }
    },
    computed: {
        ...mapGetters({
            filtroPagar: 'cobro_cartera/cartera/filtroPagar',
            graficaPagar: 'cobro_cartera/cartera/graficaPagar',
            pagar: 'cobro_cartera/cartera/pagar'
        }),
        filterPago: {
            get(){
                return this.$store.getters['cobro_cartera/cartera/filterPago']
            }, 
            set(val){
                this.$store.commit('cobro_cartera/cartera/set_filter_pago', val)
            }
        },
        pagination: {
            get(){
                return this.$store.getters['cobro_cartera/cartera/paginationPagar']
            }, 
            set(val){
                this.$store.commit('cobro_cartera/cartera/set_pagination_pagar', val)
            }
        },
        query: {
            get(){
                return this.$store.getters['cobro_cartera/cartera/query']
            }, 
            set(val){
                this.$store.commit('cobro_cartera/cartera/set_query', val)
            }
        },
        range_valor: {
            get(){
                return [
                    this.filterPago.valor_min,
                    this.filterPago.valor_max
                ]
            },
            set(val){
                this.filterPago.valor_min = val[0]
                this.filterPago.valor_max = val[1]
            }
        },
        range_dias: {
            get(){
                return [
                    this.filterPago.dias_vencimiento_min,
                    this.filterPago.dias_vencimiento_max
                ]
            },
            set(val){
                this.filterPago.dias_vencimiento_min = val[0]
                this.filterPago.dias_vencimiento_max = val[1]
            }
        },
        filterCount(){
            return this.filterPago.count()
        }
    },
    async created () {
        await this.Action_get_grafica_pagar();
        await this.Action_get_filtro_pagar();
        this.llenadoGrafica()
        this.clearFilter()
        this.Action_get_pagar();
    },
    methods:{
        ...mapActions({
            Action_get_filtro_pagar: 'cobro_cartera/cartera/Action_get_filtro_pagar',
            Action_get_grafica_pagar: 'cobro_cartera/cartera/Action_get_grafica_pagar',
            Action_get_pagar: 'cobro_cartera/cartera/Action_get_pagar',
            clearFilter: 'cobro_cartera/cartera/Action_clear_filter_pagar',
        }),
        llenadoGrafica(){
            this.graficaPorPagar.series[0].data = [
                {
                    value: this.graficaPagar?.grafica?.vencidas?.por_pagar ?? 0,
                    itemStyle: {
                        normal: {
                            barBorderRadius: [5, 5, 0, 0],
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#a90000'
                                },
                                {
                                    offset: 1,
                                    color: "#ffffff"
                                }
                            ])
                        }
                    },
                },
                this.graficaPagar?.grafica?.primer_mes?.por_pagar ?? 0,
                this.graficaPagar?.grafica?.segundo_mes?.por_pagar ?? 0,
                this.graficaPagar?.grafica?.tercer_mes?.por_pagar ?? 0,
                this.graficaPagar?.grafica?.superior_tres_meses?.por_pagar ?? 0,
            ]
            this.graficaPorPagar.series[1].data = [
                {
                    value: -this.graficaPagar?.grafica?.vencidas?.por_cobrar ?? 0,
                    itemStyle: {
                        normal: {
                            barBorderRadius: [0, 0, 5, 5],
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#ffffff'
                                },
                                {
                                    offset: 1,
                                    color: "#a90000"
                                }
                            ])
                        }
                    },
                },
                -this.graficaPagar?.grafica?.primer_mes?.por_cobrar ?? 0,
                -this.graficaPagar?.grafica?.segundo_mes?.por_cobrar ?? 0,
                -this.graficaPagar?.grafica?.tercer_mes?.por_cobrar ?? 0,
                -this.graficaPagar?.grafica?.superior_tres_meses?.por_cobrar ?? 0,
            ]
        },
        beforeSearch(){
            this.Action_get_pagar()
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        async filtrarDocumentos(){
            await this.Action_get_pagar()
            this.$refs.abrirModalFiltros.toggle()
        },
        async clearFilters(){
            await this.clearFilter()
            this.Action_get_pagar()
        },
        async handleFilter(){
            await this.clearFilter()
            this.Action_get_pagar()
            this.$refs.abrirModalFiltros.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        verCuenta(id_orden){
            this.$router.push({ name: 'compras.ver.orden', params:{id:id_orden, id_orden:id_orden} })
        }
    }
}
</script>

<style lang="scss" scoped>
.cobro-cartera-pagar{
    height: calc(100vh - 235px);
    .totales{
        min-width: 173px;
        max-width: 200px;
    }
    .cantidades{
        // min-width: 300px;
        // max-width: 300px;
        .icon{
            max-width: 37px;
            min-width: 37px;
            background: #E1E1E1;
        }
        .vencidas{
            max-width: 70px;
            min-width: 70px;
        }
        .pendientes{
            max-width: 60px;
            min-width: 60px;
        }
        .text-vencidas{
            min-width: 70px;
            max-width: 70px;
        }
        .text-vacio{
            min-width: 37px;
            max-width: 37px;
        }
        .text-pendientes{
            width: 240px;
        }
    }
}
</style>